import React from "react";
import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {Controller} from 'react-hook-form'
import PropTypes from "prop-types";

const RadioGroupControl = ({label, name, control, defaultValue, buttons, ...rest}) => {
    const readOnly = rest?.readOnly
    return (
        <Controller
            render={({field, fieldState: {error}}) => (
                <FormControl component="fieldset">
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup{...field} aria-label={name}>
                        {buttons.map(({value, label}) =>
                            <FormControlLabel key={`radio-button-${name}-${value}`}
                                              disabled={rest.disabled || readOnly}
                                              sx={{
                                                  '.MuiFormControlLabel-label.Mui-disabled': {
                                                      color: '#000000de'
                                                  }
                                              }}
                                              value={value} control={<Radio/>}
                                              label={label}/>)}

                    </RadioGroup>
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    )
}

export default RadioGroupControl

RadioGroupControl.propTypes = {
    buttons: PropTypes.array.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired
}

RadioGroupControl.defaultProps = {
    buttons: [],
    defaultValue: ''
}
