import React from "react";
import {Autocomplete, Box, Popper} from "@mui/material";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";
import CustomInput from "components/inputs/autocomplete/components/CustomInput";
import CustomChip from "components/inputs/autocomplete/components/CustomChip";

const useStyles = makeStyles(() => ({
    chip: {
        '& .MuiChip-root': {
            height: '100%'
        }
    },
}))

const AutocompleteControl = ({
                                 options,
                                 size,
                                 control,
                                 getOptionLabel,
                                 freeSolo,
                                 disabled,
                                 label,
                                 name,
                                 className = {},
                                 multiple = false,
                                 limitTags,
                                 defaultValue,
                                 isRenderOption = false,
                                 getOptionDisabled,
                                 isOptionDisabled,
                                 tagLink,
                                 withPopper, popperContent,
                                 ...rest
                             }) => {
    const classes = useStyles()
    const readOnly = rest?.readOnly || undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <Controller
                render={({field, fieldState: {error}}) => (
                    <Autocomplete
                        {...field}
                        {...rest}
                        value={multiple ? field.value ? field.value : [] : typeof field.value === 'string' ? options.find(item => field.value === item.id) : field.value}
                        className={clsx(className, {
                            [classes.chip]: multiple && size === 'small'
                        })}
                        sx={rest.sx}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, key) => <CustomChip  {...{id,
                                handleClick,
                                withPopper,
                                readOnly,
                                tagLink,
                                getTagProps,
                                option,
                                key,
                                getOptionLabel
                            }}/>)
                        }}
                        PopperComponent={props => <Popper {...props} style={{...props.style, zIndex: 99999}}/>}
                        getOptionDisabled={option => isOptionDisabled ? getOptionDisabled(option) : false}
                        getOptionLabel={option => typeof getOptionLabel === 'string' ? option[getOptionLabel] : getOptionLabel(option)}
                        options={options}
                        getOptionSelected={rest.getOptionSelected}
                        multiple={multiple}
                        limitTags={limitTags}
                        disabled={disabled || readOnly}
                        freeSolo={freeSolo}
                        renderOption={(props, option, state) => isRenderOption ? rest.renderOption(props, option, state) :
                            <Box {...props}>{option[getOptionLabel]}</Box>}
                        renderInput={(params) => <CustomInput {...{label, readOnly, size, error, params}}/>}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                defaultValue={defaultValue}
                name={name}
                control={control}
            />
            {/*<Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex: 9999}}>*/}
            {/*    <Box sx={{p: 2, bgcolor: '#FFF', boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)', borderRadius: '5px'}}>*/}
            {/*        {popperContent(0)}*/}
            {/*    </Box>*/}
            {/*</Popper>*/}
        </>


    )
}

export default AutocompleteControl

AutocompleteControl.propTypes = {
  className: PropTypes.object,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disabled: PropTypes.bool,
  freeSolo: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  getOptionLabel: PropTypes.string,
  getOptionSelected: PropTypes.func,
  isOptionDisabled: PropTypes.bool,
  isRenderOption: PropTypes.bool,
  label: PropTypes.string,
  limitTags: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  renderOption: PropTypes.any,
  rules: PropTypes.object,
  size: PropTypes.string,
  tagLink: PropTypes.string
}

AutocompleteControl.defaultProps = {
    className: {},
    disabled: false,
    freeSolo: false,
    getOptionLabel: 'value',
    limitTags: -1,
    multiple: false,
    rules: {},
    size: 'small'
}
