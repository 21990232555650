import PropTypes from 'prop-types';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import React, {useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {readOnly as readOnlyClass} from 'styles/styles';


const TextFieldControl = ({label, name, rules, defaultValue, control, multiline, ...rest}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const readOnly = rest?.readOnly || undefined;

    const type = rest.type === 'password' ? (showPassword && !readOnly ? 'text' : 'password') : rest.type || 'text';

    return (
        <Controller
            name={name}
            render={({field, fieldState: {error}}) => <TextField
                {...field}
                {...rest}
                onFocus={(event) => rest?.onFocus && rest?.onFocus(event)}
                value={field.value === null ? '' : field.value}
                error={!!error}
                label={label}
                helperText={error?.message}
                size={'small'}
                fullWidth
                multiline={multiline}
                sx={readOnly && readOnlyClass}
                variant={rest.variant || 'outlined'}
                InputLabelProps={{shrink: readOnly || field.value || field.value === 0}}
                inputProps={{
                    autocomplete: 'new-password',
                    form: {
                        autocomplete: 'off'
                    }
                }}
                disabled={readOnly || rest.disabled}
                InputProps={{
                    ...rest.InputProps,
                    type: type,
                    autoComplete: false,
                    endAdornment: rest.InputProps?.endAdornment || rest.type === 'password' && !readOnly &&
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                }}
            />
            }
            rules={rules}
            control={control}
            defaultValue={defaultValue}
        />
    );
};

export default TextFieldControl;

TextFieldControl.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any,
    disableAutoComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string
};

