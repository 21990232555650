export const readOnly = {
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover, & .Mui-focused .MuiOutlinedInput-notchedOutline, & > .MuiInputBase-root > fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#0000003b',
        borderWidth: 1
    },
    '& label.Mui-focused, & > label.Mui-disabled ': {
        color: '#0009'
    },
    '& input.Mui-disabled,& .MuiOutlinedInput-input.Mui-disabled': {
        textFillColor: '#000000de'
    }
};

export const DEFAULT_INPUT_GRID_SPACING = 3;