import React from 'react';
import {TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import PropTypes from 'prop-types';
import {readOnly as readOnlyClass} from 'styles/styles';
import {DatePicker} from '@mui/x-date-pickers';


const DatePickerControl = ({label, control, name, size, defaultValue = null, inputFormat, ...rest}) => {
    const readOnly = rest?.readOnly || undefined;
    return (<Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({field, fieldState: {error}}) => {
                return (<DatePicker
                        {...field}
                        inputFormat={inputFormat}
                        disableOpenPicker={readOnly}
                        label={label}
                        value={field.value ? field.value : null}
                        renderInput={(params) => <TextField
                            {...params}
                            error={error}
                            InputLabelProps={{shrink: readOnly}}
                            sx={readOnly && readOnlyClass}
                            helperText={error?.message}
                            inputProps={{
                                ...params.inputProps, placeholder: 'дд.мм.гггг'
                            }}
                            fullWidth variant={rest.variant} size={size}
                        />}
                        {...rest}
                    />);
            }}
        />

    );
};

export default DatePickerControl;

DatePickerControl.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium'])
};

DatePickerControl.defaultProps = {
    size: 'small', inputFormat: 'DD.MM.YYYY'
};
