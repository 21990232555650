import React from 'react';
import {Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel} from "@mui/material";
import {Controller} from 'react-hook-form'
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const CheckboxControl = ({control, name, label, ...rest}) => {
    const readOnly = rest?.readOnly;
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={rest.defaultValue}
            render={({field, fieldState: {error}}) => {
                if (readOnly)
                    return (
                        <FormControl>
                            <FormLabel>{label}</FormLabel>
                            <Typography>{field.value === true ? "Да" : "Нет"}</Typography>
                        </FormControl>
                    )
                return (
                    <FormControl>
                        <FormControlLabel
                            sx={{
                                '.MuiFormControlLabel-label.Mui-disabled': {
                                    color: '#000000de'
                                }
                            }}
                            control={<Checkbox
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value === true}
                                disabled={rest.disabled || readOnly}
                            />}
                            label={label}/>
                        <FormHelperText>{error?.message}</FormHelperText>
                    </FormControl>

                )
            }}
        />
    )
}

export default CheckboxControl

CheckboxControl.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired
}
