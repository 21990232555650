import React, {useCallback} from 'react';
import CustomModal from 'components/modal/CustomModal';
import ReactCrop from 'react-image-crop';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
    // crop: {
    //     height: 450,
    //     '& > div': {
    //         height: 450
    //     }
    // }
}));

const CropModal = ({file, open, onClose, setCrop, crop, imgRef, setCompletedCrop, onCreateCoppedPreview}) => {
    const classes = useStyles();
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    const content = <>
        <ReactCrop
            className={classes.crop}
            src={file}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onImageLoaded={onLoad}
            onComplete={c => setCompletedCrop(c)}
            imageStyle={{
                maxHeight: '100%',
                maxWidth: '100%'
            }}
        />
    </>;

    return (
        <CustomModal
            open={open}
            onClose={onClose}
            content={content}
            actionButtonText={'Сохранить'}
            onAction={() => {
                onCreateCoppedPreview();
                onClose();
            }}
        />
    );
};

export default CropModal;
