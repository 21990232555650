import {VALIDATION_SCHEMA, VALIDATORS_TYPE} from "generators/validation/constants";
import * as yup from "yup";

const generateValidators = (inputs = []) => {
    const validators = {};
    inputs?.forEach(input => {
        const {type: inputType, name, validations} = input;

        if (typeof validations === 'object' && !Array.isArray(validations)) validators[name] = validations
        else if (inputType === 'email' && !validations) validators[name] = VALIDATION_SCHEMA[VALIDATORS_TYPE.EMAIL]
    })
    return yup.object().shape(validators)
}

export default generateValidators
