import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import PropTypes from "prop-types";

const CustomModal = ({
                          open,
                          onClose,
                          title,
                          content,
                          buttons,
                          withoutContentPadding,
                          actionButtonText,
                          onAction,
                          loading,
                          loadingButtonText
                      }) => {

    const defaultButtons = <>
        <Button onClick={onClose} disabled={loading}>
            Закрыть
        </Button>
        <LoadingButton loading={loading}
                       loadingIndicator={loadingButtonText} variant={'contained'}
                       onClick={onAction}>
            {actionButtonText}
        </LoadingButton>
    </>

    return (
        <Dialog open={open} onClose={!loading && onClose} fullWidth>
            {title && <>
                <DialogTitle id="alert-dialog-title"
                             sx={{p: 2, typography: 'h4', textAlign: 'center'}}>{title}</DialogTitle>
                <Divider/>
            </>
            }
            {content && <DialogContent
                sx={{overflowY: "visible", p: withoutContentPadding ? 0 : 2, pb: 1}}>{content}</DialogContent>}
            {buttons || actionButtonText ?
                <DialogActions sx={{px: 2, pb: 2, pt: 1}}>
                    {buttons || defaultButtons}
                </DialogActions> : <></>}
        </Dialog>
    )
}

export default CustomModal

CustomModal.propTypes = {
    actionButtonText: PropTypes.string,
    buttons: PropTypes.any,
    content: PropTypes.any,
    loading: PropTypes.string,
    loadingButtonText: PropTypes.string,
    onAction: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.any.isRequired,
    title: PropTypes.string,
    withoutContentPadding: PropTypes.bool
}

CustomModal.defaultProps = {
    withoutContentPadding: false
}
