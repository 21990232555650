import React, {useState} from "react";
import {TextFieldControl} from "components/inputs";
import {CompactPicker} from "react-color";
import {Box, Popper} from "@mui/material";
import {useWatch} from "react-hook-form";

const ColorPickerInputControl = (props) => {
    const {control, name, clearErrors} = props;
    const colorValue = useWatch({
        control, name
    })
    const [color, setColor] = useState(colorValue)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleColorChange = (color) => {
        clearErrors(name);
        props.setValue(props.name, color.hex);
        setColor(color.hex);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (<>
            <TextFieldControl
                aria-describedby={id}
                InputProps={{
                    endAdornment: <Box sx={{width: 16, height: 16, backgroundColor: color}}/>
                }}
                onFocus={handleClick}
                {...props}/>
            <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex: 9999}}>
                <Box sx={{ p: 1, bgcolor: 'background.paper',  }} onClick={handleClick}>
                    <CompactPicker onChange={(color) => handleColorChange(color)}/>
                </Box>
            </Popper>

    </>

    )
}

export default ColorPickerInputControl
