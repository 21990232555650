import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {IconButton, ImageList, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Typography from "@mui/material/Typography";
import Preview from "components/inputs/FileUpload/components/Preview";
import CropModal from "components/inputs/FileUpload/components/CropModal";
import {dropzoneClasses} from "components/inputs/FileUpload/styles/dropzoneClasses";
import onCreateCroppedPreview from "components/inputs/FileUpload/onCreateCroppedPreview";
import {Delete, FilePresent} from "@mui/icons-material";

const Dropzone = ({onChange, accept, readOnly, multiple, value, aspect, dropzoneLabel, error}) => {
    const [files, setFiles] = useState([])
    const [preview, setPreview] = useState(value ? [{preview: value}] : [])

    useEffect(() => {
        if (value === null) setPreview([])
        else if (typeof value === 'string') setPreview([{preview: value}])
            else if (typeof value === 'object') setPreview([value])
    }, [value])

    const [openCropModal, setOpenCropModal] = useState(false)
    const defaultCrop = {unit: '%', width: 100, aspect: aspect}
    const [crop, setCrop] = useState(defaultCrop);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    const isImage = accept.includes('image')

    const onDrop = useCallback(acceptedFiles => {
        //подготавливаем картинку под кроппер
        if (isImage) {
            let newFiles;
            if (acceptedFiles.length === 1) {
                newFiles = acceptedFiles.map(newFile => {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => setFiles([reader.result]));
                    reader.readAsDataURL(newFile);
                })
                setOpenCropModal(true)
                setFiles(newFiles)
            } else { //подготавливаем картинку для вывода в превью
                newFiles = acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
                setPreview(newFiles)
                onChange(newFiles)
            }
        } else {
            onChange(acceptedFiles)
            const newPreview = acceptedFiles.map(file => Object.assign(file, {
                preview: `${file.path} - ${file.size} bytes`
            }))
            setPreview([...preview, ...newPreview])
        }
    }, [files])

    const {getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept} = useDropzone({
        accept,
        multiple,
        onDrop
    });

    const onDelete = (file) => {
        const newFiles = [...preview]
        newFiles.splice(newFiles.indexOf(file), 1)
        setPreview(newFiles)
        onChange(newFiles)
    }

    const style = useMemo(() => ({
        ...dropzoneClasses.dropzone,
        ...(isDragActive ? dropzoneClasses.activeStyle : {}),
        ...(isDragAccept ? dropzoneClasses.acceptStyle : {}),
        ...(isDragReject || error ? dropzoneClasses.rejectStyle : {})
    }), [isDragActive, isDragReject, isDragAccept, error]);

    return (
        <>
            {!readOnly && preview.length === 0 &&
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <Typography>{dropzoneLabel}</Typography>
            </div>
            }
            {preview.length ? isImage ? <ImageList cols={3}>
                        {preview.map(file => <Preview {...{file, onDelete, readOnly}}/>)}
                    </ImageList> :
                    <List>
                        {preview.map(file => <ListItem disablePadding>
                            <ListItemIcon><FilePresent/></ListItemIcon>
                            <ListItemText primary={file.preview}/>
                            {!readOnly && <IconButton onClick={() => onDelete(file)} size={'small'}>
                                <Delete/>
                            </IconButton>}
                        </ListItem>)}
                    </List>
                : readOnly ?
                    <Typography sx={{marginBottom: theme => theme.spacing(1)}}>Загруженные файлы
                                                                               отсутствуют</Typography> : <></>}
            {/*для кропа*/}
            {!multiple && <canvas ref={previewCanvasRef} style={{display: 'none'}}/>}
            {openCropModal && files[0] &&
            <CropModal open={openCropModal} onClose={() => setOpenCropModal(false)} file={files[0]}
                       onCreateCoppedPreview={() => onCreateCroppedPreview({
                           completedCrop,
                           previewCanvasRef,
                           imgRef,
                           setPreview,
                           multiple,
                           preview,
                           onChange,
                           setCrop,
                           defaultCrop
                       })}
                       {...{
                           crop, setCrop, imgRef, setCompletedCrop,
                       }}/>}
        </>
    )
}

export default Dropzone
