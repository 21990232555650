import * as yup from "yup";

export const VALIDATORS_TYPE = {
    EMAIL: 'email',
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
    REQUIRED: 'required',
    OBJECT: 'object',
    ARRAY: 'array'

}

export const ERROR_MESSAGE = {
    [VALIDATORS_TYPE.EMAIL]: "Неверный формат email",
    [VALIDATORS_TYPE.REQUIRED]: "Поле обязательное",
    [VALIDATORS_TYPE.DATE]: "Неверно введена дата",
    [VALIDATORS_TYPE.NUMBER]: "Должно быть введено число",
    [VALIDATORS_TYPE.STRING]: "Должан быть введена строка"
}

export const VALIDATION_SCHEMA = {
    [VALIDATORS_TYPE.EMAIL]: yup.string().email("Неверный формат email"),
}
