import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {SearchField} from 'components/inputs/index';

const SearchFieldControl = ({
                                name,
                                label,
                                control,
                                multiple,
                                disabled,
                                limit,
                                rules,
                                additionalWhere,
                                ...rest
                            }) => {
    const readOnly = rest?.readOnly || undefined;
    return (
        <Controller
            render={({field, fieldState: {error}}) => (
                <>
                    <SearchField
                        {...{...field, ...rest, readOnly, multiple, error, label}}
                        value={multiple ? field.value ? field.value : [] : typeof field.value === 'string' ? options.find(item => field.value === item.id) : field.value}
                        onChange={(_, value) => field.onChange(value)}
                        filterOptions={(x) => x}
                        onInputChange={(_, value, reason) => {
                            // очищаем ошибки при начале ввода если они есть
                            if (!!error) rest.clearErrors(name);
                            if (reason === 'input') {
                                //запрашиваем поиск по текущему значению в поле ввода
                                rest.refetch({
                                    take: limit ? parseInt(limit) : 15,
                                    where: additionalWhere ? additionalWhere(value) : {
                                        [rest.whereQueryField || rest.getOptionLabel]: {
                                            contains: value,
                                            mode: 'insensitive'
                                        }
                                    }
                                });
                            } else if (reason === 'reset' && !value || reason === 'clear') {
                                rest.refetch({
                                    take: limit ? parseInt(limit) : 15,
                                    where: additionalWhere
                                });
                            }
                        }}
                    />
                </>
            )}
            defaultValue={rest.defaultValue}
            name={name}
            control={control}
            rules={rules}
        />
    );
};

export default SearchFieldControl;

SearchFieldControl.propTypes = {
    className: PropTypes.any,
    clearErrors: PropTypes.any.isRequired,
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    freeSolo: PropTypes.bool,
    getOptionDisabled: PropTypes.any,
    getOptionLabel: PropTypes.string,
    getOptionSelected: PropTypes.any,
    isOptionDisabled: PropTypes.any,
    isRenderOption: PropTypes.any,
    label: PropTypes.string.isRequired,
    limit: PropTypes.any,
    limitTags: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    refetch: PropTypes.func.isRequired,
    renderOption: PropTypes.any,
    size: PropTypes.string,
    tagLink: PropTypes.string,
    whereQueryField: PropTypes.string
};

SearchFieldControl.defaultProps = {
    getOptionLabel: 'name',
    limitTags: -1,
    multiple: false,
    queryResult: [],
    size: 'small'
};
