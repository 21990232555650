import React from 'react';
import {Autocomplete} from '@mui/lab';
import CustomChip from 'components/inputs/autocomplete/components/CustomChip';
import CustomInput from 'components/inputs/autocomplete/components/CustomInput';
import {Box, createFilterOptions, FormLabel, Popover, Stack} from '@mui/material';
import SearchFieldControl from 'components/inputs/autocomplete/SearchFieldControl';
import Link from 'next/link';
import {ROUTES} from 'constants/routes';


const SearchField = (props) => {
    const {
        multiple,
        value,
        isOptionDisabled,
        getOptionDisabled,
        getOptionSelected,
        tagLink,
        getOptionLabel,
        label,
        size,
        error,
        whereQueryField,
        options,
        disabled,
        freeSolo,
        limit,
        limitTags,
        isRenderOption,
        renderOption, onChange, refetch,
        withPopper, popperContent, createEntityPath
    } = props;
    const readOnly = props?.readOnly || undefined;
    const filter = createFilterOptions();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleClick = (event, index) => {
        setAnchorEl({anchorEl: anchorEl ? null : event.currentTarget, index});
    };

    return (
        <>
            {
                tagLink && !multiple && readOnly ?
                    <Stack direction={'column'}>
                        <FormLabel sx={{lineHeight: '1em'}}>{label}</FormLabel>
                        {value && value[getOptionLabel] ? <Link href={`${tagLink}?id=${value.id}`} passHref>
                            <a href={`${tagLink}?id=${value.id}`} target={'_blank'}>
                                {value[getOptionLabel]}
                            </a>
                        </Link> : '-'}
                    </Stack>
                    :
                    <>
                        <Autocomplete
                            {...props}
                            value={value}
                            getOptionDisabled={option => isOptionDisabled ? getOptionDisabled(option) : false}
                            getOptionSelected={getOptionSelected}
                            sx={{
                                '& .MuiChip-root': {
                                    height: '100%'
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderTags={(value, getTagProps) => value.map((option, index) => <CustomChip {...{
                                withPopper, handleClick,
                                readOnly,
                                tagLink,
                                getTagProps,
                                option,
                                index,
                                getOptionLabel
                            }} />)}
                            renderInput={(params) => <CustomInput {...{label, readOnly, size, error, params}} />}
                            filterSelectedOptions={true}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const {inputValue} = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option.id);
                                const optionLabel = typeof getOptionLabel === 'string' ? getOptionLabel : whereQueryField;

                                if (inputValue !== '' && !isExisting && !!createEntityPath) {
                                    filtered.push({
                                        inputValue,
                                        [optionLabel]: `Создать "${inputValue}"`
                                    });
                                }
                                return filtered;
                            }}
                            onInputChange={(event, value, reason) => {
                                // очищаем ошибки при начале ввода если они есть
                                if (props.onInputChange) props.onInputChange(event, value, reason);
                                else {
                                    if (reason === 'input') {
                                        //запрашиваем поиск по текущему значению в поле ввода
                                        refetch({
                                            take: limit ? parseInt(limit) : 15,
                                            where: {
                                                [whereQueryField || getOptionLabel]: {
                                                    contains: value,
                                                    mode: 'insensitive'
                                                }
                                            }
                                        });
                                    } else if (reason === 'reset' && !value || reason === 'clear') {
                                        refetch({
                                            take: limit ? parseInt(limit) : 15,
                                            where: undefined
                                        });
                                    }
                                }
                            }}
                            onChange={(event, value, reason) => {
                                if (value && value.inputValue || Array.isArray(value) && value?.some((i) => !!i.inputValue)) {
                                    const inputValue = Array.isArray(value) ? value[value.length - 1].inputValue : value.inputValue;
                                    if (createEntityPath === ROUTES.USER_CREATE) window.open(`/admin${createEntityPath}`);
                                    else window.open(`/admin${createEntityPath}?name=${inputValue}`);
                                } else {
                                    onChange(event, value, reason);
                                }
                            }}
                            renderOption={(props, option, state) => isRenderOption ?
                                renderOption(props, option, state) :
                                <Box {...props}>{option.addTitle || option[getOptionLabel]}</Box>
                            }
                            getOptionLabel={option => {
                                const value = typeof getOptionLabel === 'string' ? option[getOptionLabel] : getOptionLabel(option);
                                // Value selected with enter, right from the input
                                if (typeof value === 'string') {
                                    return value;
                                }
                                // Add "xxx" option created dynamically
                                if (value?.inputValue) {
                                    return value.inputValue;
                                }

                                return value;
                            }}
                            options={options || []}
                            errors={error}
                            multiple={multiple}
                            limitTags={limitTags}
                            disabled={disabled || readOnly}
                            freeSolo={freeSolo}
                        />
                        {
                            popperContent && anchorEl?.anchorEl &&
                            <Popover id={id} open={open} anchorEl={anchorEl.anchorEl} onClose={() => setAnchorEl(null)}
                                     style={{zIndex: 9999}}>
                                <Box sx={{
                                    p: 2,
                                    bgcolor: '#FFF',
                                    boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)',
                                    borderRadius: '5px'
                                }}>
                                    {popperContent(anchorEl.index)}
                                </Box>
                            </Popover>
                        }
                    </>


            }


        </>

    );
};


export default SearchField;

SearchFieldControl.defaultProps = {
    getOptionLabel: 'name',
    limitTags: -1,
    multiple: false,
    queryResult: [],
    size: 'small',
    freeSolo: false
};
