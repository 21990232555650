import theme from "theme";

export const dropzoneClasses = {
    dropzone: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    activeStyle: {
        borderColor: theme.palette.primary.main
    },
    acceptStyle: {
        borderColor: theme.palette.primary.main
    },
    rejectStyle: {
        borderColor: theme.palette.secondary.main
    }
}
