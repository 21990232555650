import React from "react";
import {
    AutocompleteControl,
    CheckboxControl,
    DatePickerControl,
    FileUpload,
    RadioGroupControl,
    TextFieldControl, SwitchControl, ColorPickerInputControl, SearchFieldControl
} from "components/inputs";
import {MODE} from "constants/mode";
import {useRouter} from "next/router";
import PropTypes from "prop-types";

export const INPUT_TYPES = {
    DATE: 'date',
    TEXT: 'text',
    AUTOCOMPLETE: 'autocomplete',
    CHECKBOX: 'checkbox',
    RADIO_GROUP: 'radio_group',
    UPLOAD: 'upload',
    SEARCH: 'search',
    SWITCH: 'switch',
    COLOR: 'color'
}

export const INPUT_DEFAULT_VALUE = {
    [INPUT_TYPES.DATE]: null,
    [INPUT_TYPES.TEXT]: '',
    [INPUT_TYPES.AUTOCOMPLETE]: {id: '', value: ''},
    [INPUT_TYPES.CHECKBOX]: false,
    [INPUT_TYPES.RADIO_GROUP]: '',
    [INPUT_TYPES.SWITCH]: false
}

const GeneratedInput = ({input, control, setValue, clearErrors, ...rest}) => {
    const {label, name, inputType, permission = true, isListFilter = true} = input;
    if (!permission) return <></>
    const {query} = useRouter()
    const {mode} = query
    const readOnly = (mode === MODE.VIEW && isListFilter) || input.update === false && mode === MODE.UPDATE || input.create === false && MODE.CREATE || input.readOnly;

    switch (inputType) {
        case INPUT_TYPES.DATE: {
            return <DatePickerControl label={label} name={name} control={control}
                                      {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.TEXT: {
            return <TextFieldControl control={control} name={name} label={label}
                                     type={input.type} {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.AUTOCOMPLETE: {
            return <AutocompleteControl name={name} label={label} options={input.options}
                                        control={control}
                                        defaultValue={input.defaultValue || input.multiple ? [] : INPUT_DEFAULT_VALUE[INPUT_TYPES.AUTOCOMPLETE]}
                                        {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.CHECKBOX: {
            return <CheckboxControl control={control} name={name} label={label}  {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.RADIO_GROUP: {
            return <RadioGroupControl name={name} control={control} label={label}
                                      buttons={input.buttons}  {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.UPLOAD: {
            return <FileUpload control={control} name={name} label={label}  {...{...input,...rest, readOnly}}/>
        }
        case INPUT_TYPES.SEARCH: {
            return <SearchFieldControl {...{...input, ...rest, control, setValue, clearErrors, readOnly}}/>
        }
        case INPUT_TYPES.SWITCH: {
            return <SwitchControl  {...{control, ...input, ...rest, readOnly}}/>
        }
        case INPUT_TYPES.COLOR: {
            return <ColorPickerInputControl {...{control, ...input, ...rest, setValue, readOnly, clearErrors}}/>
        }
        default:
            return <></>
    }

}

export default GeneratedInput

GeneratedInput.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.object.isRequired,
  input: PropTypes.any.isRequired,
  setValue: PropTypes.any
}
