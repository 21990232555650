import React from "react";
import {IconButton, ImageListItem} from "@mui/material";
import {Delete} from "@mui/icons-material";

const Preview = ({file, onDelete, readOnly}) => {
    return (
        <ImageListItem sx={{
            position: "relative",
            '& .MuiImageListItem-img': {
                objectFit: 'contain'
            }
        }}>
            {!readOnly && <IconButton onClick={() => onDelete(file)} size={'small'}
                                      sx={{position: 'absolute', top: -3, right: -3, borderRadius: '50%'}}>
                <Delete/>
            </IconButton>}
            {file && <img
                src={file.preview}
                alt={file.name}
            />}
        </ImageListItem>
    )
}

export default Preview
