import React from 'react';
import Link from "components/Link";
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";


const CustomChip = ({readOnly, tagLink, getTagProps, option, index, getOptionLabel, withPopper, id, handleClick}) => {
    const label = option ? typeof getOptionLabel === 'string' ? option[getOptionLabel] : getOptionLabel(option) : '';

    const ChipComponent = (props) => <Chip
        {...props}
        {...getTagProps({index})}
        onDelete={!readOnly && getTagProps({index}).onDelete}
        disabled={readOnly ? false : getTagProps({index}).disabled}
        underline={'none'}
        target={'_blank'}
        clickable={true}
        href={readOnly && tagLink && `${tagLink}?id=${option.id}`}
        component={readOnly && tagLink && Link}
        sx={{...(readOnly && tagLink && {cursor: 'pointer'})}}
        label={label}/>

    if (withPopper) {
        return (
            <>
                <Box  aria-describedby={id} type="button" onClick={(event) => handleClick(event, index)} sx={{cursor: 'pointer'}}>
                    <ChipComponent/>
                </Box>


            </>

        )
    }
    return <ChipComponent/>
}

export default CustomChip
