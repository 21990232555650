import React from "react";
import {Controller} from 'react-hook-form'
import PropTypes from "prop-types";
import {Box, FormHelperText, FormLabel} from "@mui/material";
import Dropzone from "components/inputs/FileUpload/components/Dropzone";

export const ASPECT = {
    LOGO: '1',
    NEWS: '16/9'
}

//кроппер не расчитан на загрузку нескольких файлов

const FileUploadComponent = ({
                                  label,
                                  name,
                                  control,
                                  multiple,
                                  defaultValue,
                                  accept,
                                  aspect,
                                  dropzoneLabel,
                                  ...rest
                              }) => {
    const readOnly = rest?.readOnly;
    return (
        <Controller
            render={({field: {onChange, ...props}, fieldState: {error}}) => (
                <Box sx={{position:'relative', marginBottom: theme => theme.spacing(1)}}>
                    {readOnly && <FormLabel>{label}</FormLabel>}
                    <Dropzone
                        aspect={aspect}
                        accept={accept}
                        multiple={multiple}
                        onChange={files => {
                            onChange(multiple ? files : files[0])
                        }}
                        readOnly={readOnly}
                        error={!!error}
                        dropzoneLabel={dropzoneLabel}
                        {...props}
                        {...rest}
                    />
                    {error && <FormHelperText sx={{position: 'absolute', left: 5}} error={true}>{error?.message}</FormHelperText>}
                </Box>
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    )
}

export default FileUploadComponent

FileUploadComponent.propTypes = {
  accept: PropTypes.string,
  aspect: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dropzoneLabel: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired
}

FileUploadComponent.defaultProps = {
  accept: "image/*",
  aspect: ASPECT.LOGO,
  multiple: false
}
