import React from "react";
import {TextField} from "@mui/material";
import {readOnly as readOnlyClass} from "styles/styles";

const CustomInput = ({label, readOnly, size, error, params}) => {
    return (
        <TextField
            {...params}
            label={label}
            error={!!error}
            sx={readOnly && readOnlyClass}
            helperText={error?.message}
            size={size}
            variant="outlined"
            InputLabelProps={{
                shrink: readOnly
            }}
            inputProps={{...params.inputProps, readOnly: readOnly}}
        />
    )
}

export default CustomInput
