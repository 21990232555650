const onCreateCroppedPreview = ({completedCrop, previewCanvasRef, imgRef, setPreview, multiple, preview, onChange, setCrop, defaultCrop}) => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
    }
    //отрисовываем обрезанное на канвасе
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );
    //превращаем канвас в файл
    canvas.toBlob(
        (blob) => {
            const file = new File([blob], "name")
            const fileWithPreview = Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
            //закидываем в превью
            setPreview(multiple ? [...preview, fileWithPreview] : [fileWithPreview])
            //закидываем в форму
            onChange([fileWithPreview])

        },
        'image/png',
        1
    );

    setCrop(defaultCrop)
}

export default onCreateCroppedPreview
